<template>
  <div class="row">
    <div class="card card-custom gutter-b col mr-5">
      <!--begin::Header-->
      <div class="card-header">
        <h3 class="card-title font-weight-bolder text-dark">
          {{ $t("CLIENTS.INDIVIDUAL") }}
        </h3>
      </div>
      <!--end::Header-->

      <!--begin::Card Body-->
      <form class="form">
        <div class="card-body mx-4">
          <!-- Energy type -->
          <b-input-group
            data-app
            :prepend="$t('FILTER.ENERGY_TYPE_LABEL')"
            class="form-group col-6 p-0"
          >
            <v-autocomplete
              class="input-group custom-select"
              v-model="energyType"
              :items="energyTypes"
              autocomplete="not-today"
              solo
              flat
              dense
              :no-data-text="$t('FILTER.NO_DATA')"
              @change="onChangeEnergyTypeCompany"
            ></v-autocomplete>
          </b-input-group>

          <div class="row mb-4 ml-0 align-items-center">
            <v-text-field
              v-model="searchClient"
              :label="$t('CLIENTS.SEARCH')"
              single-line
              hide-details
            ></v-text-field>
            <div>
              <button class="ml-3 btn btn-primary" @click="onBlurClientSearch">
                {{ $t("CLIENTS.SEARCH") }}
              </button>
            </div>
          </div>

          <v-data-table
            :headers="headers"
            :items="items"
            :loading="loading"
            loading-text="Loading... Please wait"
            :server-items-length="totalCompanies"
            :options.sync="options"
          ></v-data-table>
        </div>
      </form>
      <!--end::Card Body-->
    </div>

    <div class="card card-custom gutter-b col">
      <div
        class="card-header d-flex jusift-content-between align-content-center"
      >
        <h3 class="card-title font-weight-bolder text-dark">
          {{ $t("CLIENTS.GROUPS") }}
        </h3>
        <!-- add new group dialog -->
        <v-dialog v-model="dialog" width="500">
          <template v-slot:activator="{ on, attrs }">
            <button
              class="btn btn-primary"
              v-bind="attrs"
              v-on="on"
              style="height: fit-content"
            >
              {{ $t("CLIENTS.ADD") }}
            </button>
          </template>

          <v-card>
            <v-card-title>
              <span class="text-h5">{{ groupDialogTitle() }}</span>
            </v-card-title>

            <v-card-text class="px-10">
              <form class="form">
                <b-input-group
                  class="form-group mt-4 mb-0"
                  :prepend="$t('CLIENTS.GROUP_NAME')"
                >
                  <b-form-input
                    v-model="newGroupName"
                    @blur="onBlurNewGroupName"
                  ></b-form-input>
                </b-input-group>
                <p v-if="groupNameWarning" class="m-0 text-warning">
                  {{ $t("CLIENTS.WARNING_NAME") }}
                </p>

                <p class="h6 m-0 mt-4 text-dark">{{ $t("CLIENTS.CLIENTS") }}</p>

                <v-autocomplete
                  class="p-0"
                  v-model="newGroupCompanies"
                  :items="allCompanies"
                  item-text="name"
                  autocomplete="not-today"
                  multiple
                  return-object
                  :no-data-text="$t('FILTER.NO_DATA')"
                ></v-autocomplete>

                <div></div>
              </form>
              <p class="h-4 mt-4 text-danger">{{ addNewGroupError }}</p>
            </v-card-text>
            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <button class="btn btn-secondary mr-5" @click="closeResetDialog">
                {{ $t("EVENT_MANAGEMENT.CANCEL") }}
              </button>
              <button
                v-if="!isEditDialog"
                class="btn btn-primary"
                @click="addGroup"
              >
                {{ $t("CLIENTS.SAVE") }}
              </button>
              <button v-else class="btn btn-primary" @click="editGroup">
                {{ $t("CLIENTS.SAVE") }}
              </button>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <form class="form">
        <div class="card-body mx-4">
          <b-input-group
            data-app
            :prepend="$t('FILTER.ENERGY_TYPE_LABEL')"
            class="form-group col-6 p-0"
          >
            <v-autocomplete
              class="input-group custom-select"
              v-model="energyTypeGroup"
              :items="energyTypes"
              autocomplete="not-today"
              solo
              flat
              dense
              :no-data-text="$t('FILTER.NO_DATA')"
              @change="onChangeEnergyTypeGroup"
            ></v-autocomplete>
          </b-input-group>

          <div class="row ml-0 mb-4 align-items-center">
            <v-text-field
              v-model="searchGroup"
              :label="$t('CLIENTS.SEARCH')"
              single-line
              hide-details
            ></v-text-field>
            <div>
              <button class="ml-3 btn btn-primary" @click="onBlurGroupSearch">
                {{ $t("CLIENTS.SEARCH") }}
              </button>
            </div>
          </div>

          <v-data-table
            :headers="headersGroup"
            :items="groups"
            item-key="group"
            class="elevation-1"
            :loading="loadingGroup"
            :server-items-length="totalGroups"
            :options.sync="optionsGroup"
            show-expand
            :single-expand="false"
            loading-text="Loading... Please wait"
          >
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <div
                  class="col my-2"
                  v-for="company in item.companies"
                  :key="company.cui"
                >
                  {{ company.name }} <span class="col mx-1">-</span>
                  {{ company.cui }}
                </div>
              </td>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon class="mr-5" small @click="onClickEditGroup(item)">
                mdi-pencil
              </v-icon>
              <v-icon small @click="deleteGroup(item)"> mdi-delete </v-icon>
            </template>
          </v-data-table>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import EnergyService from "@/core/services/energy.service";
import ApiService from "@/core/services/api.service";

export default {
  name: "Clients",
  components: {},
  data() {
    return {
      dialog: false,
      isEditDialog: false,
      headers: [],
      headersGroup: [],
      items: [],
      searchClient: "",
      totalCompanies: 0,
      totalGroups: 0,
      groups: [],
      searchGroup: "",
      energyTypes: [],
      energyType: "",
      energyTypeGroup: "",
      loading: true,
      loadingGroup: true,
      options: {},
      optionsGroup: {},
      newGroupCompanies: [],
      newGroupName: "",
      oldGroupName: "",
      newGroupId: 0,
      addNewGroupError: "",
      groupNameWarning: false,
      allCompanies: [],
      allGasCompanies: [],
      allElectricCompanies: [],
    };
  },
  watch: {
    optionsGroup: {
      handler() {
        this.getGroups();
      },
      deep: true,
    },
    options: {
      handler() {
        this.getIndividualCompanies();
      },
      deep: true,
    },
  },
  methods: {
    onBlurNewGroupName() {
      this.groupNameWarning = false;
      for (let i = 0; i < this.groups.length; i++) {
        if (this.newGroupName == this.groups[i].group) {
          this.groupNameWarning = true;
          return;
        }
      }
    },
    groupDialogTitle() {
      return this.isEditDialog
        ? this.$t("CLIENTS.EDIT_GROUP")
        : this.$t("CLIENTS.ADD_GROUP");
    },
    async onClickEditGroup(item) {
      this.isEditDialog = true;

      this.newGroupCompanies = item.companies;
      this.newGroupId = item.id;
      this.newGroupName = item.group;
      this.oldGroupName = item.group;

      this.dialog = true;
    },
    async deleteGroup(item) {
      await EnergyService.deleteGroup(this.energyTypeGroup, item);
      await this.getGroups();
    },
    addGroup() {
      this.isEditDialog = false;
      this.addEditNewGroup();
    },
    editGroup() {
      this.addEditNewGroup();
    },
    hasErrorGroupName(name) {
      if (name.trim().length == 0) {
        this.addNewGroupError = this.$t("CLIENTS.ERROR_NAME");
        return true;
      }
      return false;
    },
    hasErrorGroupCompanies() {
      if (this.newGroupCompanies.length == 0) {
        this.addNewGroupError = this.$t("CLIENTS.ERROR_LIST");
        return true;
      }
      return false;
    },
    closeResetDialog() {
      this.dialog = false;
      this.isEditDialog = false;
      this.newGroupName = "";
      this.newGroupCompanies = [];
    },
    getGroupCuiList() {
      let cuis = [];
      for (let i = 0; i < this.newGroupCompanies.length; i++) {
        cuis.push(this.newGroupCompanies[i].cui);
      }
      return cuis;
    },
    async addEditNewGroup() {
      this.addNewGroupError = "";
      if (
        this.hasErrorGroupName(this.newGroupName) ||
        this.hasErrorGroupCompanies()
      ) {
        return;
      }

      let cuis = this.getGroupCuiList();

      if (this.isEditDialog) {
        EnergyService.editGroup(
          this.energyType,
          cuis,
          this.newGroupName,
          this.oldGroupName,
          this.newGroupId
        )
          .then((response) => {
            if (response) {
              this.getGroups()
                .then(() => {
                  this.closeResetDialog();
                })
                .catch(() => {
                  this.closeResetDialog();
                });
            } else {
              this.addNewGroupError = this.$t("CLIENTS.ERROR_OTHER");
            }
          })
          .catch(() => {
            this.addNewGroupError = this.$t("CLIENTS.ERROR_EXISTS");
          });
      } else {
        EnergyService.addGroup(this.energyType, cuis, this.newGroupName)
          .then((response) => {
            if (response) {
              this.getGroups()
                .then(() => {
                  this.closeResetDialog();
                })
                .catch(() => {
                  this.closeResetDialog();
                });
            } else {
              this.addNewGroupError = this.$t("CLIENTS.ERROR_OTHER");
            }
          })
          .catch(() => {
            this.addNewGroupError = this.$t("CLIENTS.ERROR_EXISTS");
          });
      }
    },
    async onBlurClientSearch() {
      await this.getIndividualCompanies();
    },
    async onBlurGroupSearch() {
      await this.getGroups();
    },
    async getIndividualCompanies() {
      let page = this.options.page;
      let itemsPerPage = this.options.itemsPerPage;
      let response = await EnergyService.getIndividualCompanies(
        (page - 1) * itemsPerPage, // page starts from 1
        itemsPerPage,
        this.energyType,
        this.searchClient
      );
      this.items = response.items;
      this.totalCompanies = response.total;
      this.loading = false;
    },
    async getGroups() {
      let page = this.optionsGroup.page;
      let itemsPerPage = this.optionsGroup.itemsPerPage;
      let response = await EnergyService.getCompanyGroups(
        (page - 1) * itemsPerPage,
        itemsPerPage,
        this.energyTypeGroup,
        this.searchGroup
      );
      this.groups = response.items;
      this.totalGroups = response.total;
      this.loadingGroup = false;
    },
    onChangeEnergyTypeGroup() {
      this.getGroups();
      this.newGroupCompanies = [];
      if (this.energyTypeGroup == "Electric") {
        this.allCompanies = this.allElectricCompanies;
      } else if (this.energyTypeGroup == "Gas") {
        this.allCompanies = this.allGasCompanies;
      }
    },
    onChangeEnergyTypeCompany() {
      this.getIndividualCompanies();
    },
    async getEnergyCompanies() {
      var _this = this;
      ApiService.post("/companies/energy").then((response) => {
        _this.allElectricCompanies = response.data;
        _this.allCompanies = _this.allElectricCompanies;
      });
    },
    async getGasCompanies() {
      var _this = this;
      ApiService.post(`/companies/gas`).then((response) => {
        _this.allGasCompanies = response.data;
      });
    },
  },
  async mounted() {
    this.headers = [
      { text: this.$t("ONBOARD.COMPANY_NAME"), value: "name", sortable: false },
      { text: this.$t("ONBOARD.CUI"), value: "cui", sortable: false },
    ];
    this.headersGroup = [
      { text: this.$t("CLIENTS.GROUP"), value: "group", sortable: false },
      {
        text: this.$t("EVENT_MANAGEMENT.TABLE_HEADER.ACTIONS"),
        value: "actions",
        sortable: false,
      },
    ];

    this.energyTypes = [
      this.$t("FILTER.ENERGY.ENERGY"),
      this.$t("FILTER.ENERGY.GAS"),
    ];

    this.energyType = this.energyTypes[0];
    this.energyTypeGroup = this.energyTypes[0];

    await this.getGasCompanies();
    await this.getEnergyCompanies();
  },
};
</script>
